import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  TypedResponse,
  json,
  type MetaFunction,
} from '@remix-run/cloudflare';
import { Link } from '@remix-run/react';

import { Camp67Header } from '../components/camp67-header/camp67-header';

import { Footer } from '../components/footer';
import { sections, useInitializeSectionAnalytics } from '../hooks/use-section-analytics';
import { OneOfAKindSection } from '../sections/one-of-a-kind';
import { LocationSection } from '../sections/location';
import { AccommodationAndAmenitiesSection } from '../sections/accommodation-and-amenities';
import { ExperienceKumbhSection } from '../sections/experience-kumbh';
import { JoinUsSection } from '../sections/join-us';
import { useIsMobile } from '../hooks/use-is-mobile';
import { ApplicationEnvironment } from '../environment';
import { getOrigin } from '../get-origin';
import { retry } from '../retry';
import { propagateError } from '../propagate-error';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AmplitudeEvent, analyticsState, ButtonTag } from '../state/amplitude';
import { getLowestPricedTent, getPrice } from '../state/get-tent-price';
import { WeLoveKumbhSection } from '../sections/we-love-kumbh';
import { images } from '../constants/images';
import clsx from 'clsx';
import Sprite from '../sprites/sprite';
import { displayPrice } from '../state/prices';
import { useMessages } from '../i18n/useMessages';

export const meta: MetaFunction = () => {
  return [
    { title: 'Kumbh 2025 Accommodation Packages - camp67' },
    {
      name: 'description',
      content: 'Book now for Kumbh Mela 2025. Affordable. Peaceful. Uniquely Kumbh.',
    },
    {
      tagName: 'link',
      name: 'canonical',
      href: 'https://camp67.com',
    },
  ];
};

export const action = async (args: ActionFunctionArgs) => {
  const request = args.request;
  const origin = getOrigin(request);
  const cookie = request.headers.get('cookie') ?? '';
  const environment = args.context.cloudflare.env as ApplicationEnvironment;

  const apiUrl = environment.API_URL;
  const formData = await request.formData();

  const email = formData.get('email')?.toString() ?? '';
  const intent = formData.get('intent')?.toString() ?? '';

  if (intent === 'subscribe') {
    try {
      const response = await retry('post-subscription', (signal) =>
        fetch(`${apiUrl}/api/subscribe`, {
          body: JSON.stringify({ email }),
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            origin,
            cookie,
          },
          signal,
        }),
      );

      if (!response.ok) {
        throw Error('Error: subscription failed');
      }

      return json({ success: true });
    } catch (e) {
      propagateError(e);
    }

    return json({ success: false });
  } else {
    const name = formData.get('name')?.toString() ?? '';
    const phone = formData.get('phone')?.toString() ?? '';
    const inquiry = formData.get('inquiry')?.toString() ?? '';

    try {
      const response = await retry('post-inquiry', (signal) =>
        fetch(`${apiUrl}/api/inquiry`, {
          body: JSON.stringify({ email, name, phone, inquiry }),
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            origin,
            cookie,
          },
          signal,
        }),
      );

      if (!response.ok) {
        throw Error('Error: inquiry failed');
      }

      return json({ success: true });
    } catch (e) {
      propagateError(e);
    }

    return json({ success: false });
  }
};

const headerCarousel = [
  {
    src: images['hero-cover-v2'],
    alt: 'People participating in the holy dip in the River Ganges',
  },
  {
    src: images['twin-tent-v2-1'],
    alt: 'The twin room option offered by camp67.',
  },
  {
    src: images['family-tent-v2-1'],
    alt: 'The family room option offered by camp67.',
  },
  {
    src: images['header-james'],
    alt: 'Esteemed guests of Kumbh Sukrit 2019 from Italy, enjoying the Kumbh crowd.',
  },
  {
    src: images['family-plus-tent-v2-1'],
    alt: 'The family room plus option offered by camp67.',
  },
  {
    src: images['dorm-bunks-v2'],
    alt: 'The dormitory bunk option offered by camp67.',
  },
  {
    src: images['dorm-singles-v2'],
    alt: 'The dormitory single option offered by camp67.',
  },
];

const totalImages = headerCarousel.length;

type CountResponse =
  | {
      success: false;
      error: string;
    }
  | {
      success: true;
      count: number;
    };

export const loader = async ({
  request,
  context,
}: LoaderFunctionArgs): Promise<TypedResponse<CountResponse>> => {
  const env = context.cloudflare.env as ApplicationEnvironment;
  const requestCookie = request.headers.get('cookie') ?? '';

  try {
    const response = await retry('get-booking-count', (signal) =>
      fetch(`${env.API_URL}/api/booking/count`, {
        headers: { origin: getOrigin(request), Cookie: requestCookie },
        signal,
      }),
    );

    const { count } = (await response.json()) as { count: number };
    return json({ success: true, count }, 200);
  } catch (err) {
    console.log(err);
    propagateError(err);
    return json({ success: false, error: 'Internal server error' }, 500);
  }
};

type TransformConfig = { transform: string }[] | { objectPosition: string }[];

const DESKTOP_TRANSFORMS = [{ transform: 'scale(1)' }, { transform: 'scale(1.25)' }];
const MOBILE_TRANSFORMS = [{ objectPosition: '30% 50%' }, { objectPosition: '60% 50%' }];
const MOBILE_TRANSFORMS_REVERSE = [
  { objectPosition: '60% 50%' },
  { objectPosition: '30% 50%' },
];

export default function Index() {
  const animationRef = useRef<Animation | null>(null);

  useInitializeSectionAnalytics();

  const [activeImage, setActiveImage] = useState(0);

  const isMobile = useIsMobile();

  useEffect(() => {
    function startAnimation(activeImage: number) {
      const image = document.getElementById(`header-carousel-image-${activeImage}`);

      const oldAnimation = animationRef.current;

      let transformConfig: TransformConfig = DESKTOP_TRANSFORMS;
      if (isMobile && activeImage !== 0 && activeImage !== 3) {
        transformConfig =
          activeImage % 2 === 0 ? MOBILE_TRANSFORMS : MOBILE_TRANSFORMS_REVERSE;
      }
      animationRef.current = new Animation(
        new KeyframeEffect(image, transformConfig, {
          duration: 15000,
        }),
      );
      animationRef.current.play();

      oldAnimation?.pause();
      oldAnimation?.commitStyles();

      return activeImage;
    }

    const timer = window.setInterval(() => {
      setActiveImage((prev) => {
        const activeImage = (prev + 1) % totalImages;
        startAnimation(activeImage);

        return activeImage;
      });
    }, 8000);

    startAnimation(0);

    return () => clearInterval(timer);
  }, [isMobile]);

  const getMessage = useMessages();

  const tagline = getMessage('camp67-header.tagline');
  const typewriterPrefix = getMessage('camp67-header.typewriter.prefix');
  const serene = getMessage('camp67-header.typewriter.serene');
  const typewriterSuffixStart = getMessage('camp67-header.typewriter.suffix.start');
  const typewriterSuffixEnd = getMessage('camp67-header.typewriter.suffix.end');

  return (
    <>
      <section
        id={sections['header']}
        className="relative flex flex-col w-full min-h-[780px] xs:h-[840px] sm:h-[880px] md:h-[900px] lg:h-[960px] xl:h-[1008px] z-0 overflow-hidden"
      >
        <Camp67Header isLogoLight={true} />
        {headerCarousel.map((carouselItem, index) => (
          <img
            className={clsx(
              'camp67HeaderItem w-full min-h-[900px] absolute top-0 left-0 -z-20 object-cover object-[40%_50%] xs:object-center -mt-8',
              {
                'opacity-100': index === activeImage,
                'opacity-0': index !== activeImage,
              },
            )}
            src={carouselItem.src}
            alt={carouselItem.alt}
            key={index}
            loading="lazy"
            id={`header-carousel-image-${index}`}
          />
        ))}
        <div className="absolute left-1/2 -translate-x-1/2 -translate-y-[62.5%] top-[62.5%] sm:-translate-y-[60%] sm:top-[60%] w-full">
          <hgroup className="text-center">
            <p className="px-4 font-serif uppercase text-xs sm:text-sm m-auto tracking-wider text-center md:text-sm xl:text-base text-yellow-250 font-bold">
              {tagline}
            </p>
            <h1 className="flex flex-col md:block text-[30px] leading-[36px] font-serif font-medium text-white mt-2 md:text-5xl md:leading-7 xl:text-6xl xl:leading-9">
              {typewriterPrefix}{' '}
              <span>
                <span
                  id="header-typewriter"
                  className="text-yellow-250 h-0 md:h-[unset] font-semibold"
                >
                  {serene}
                </span>
                <br className="hidden md:block" /> {typewriterSuffixStart}{' '}
                <br className="block md:hidden" />
                {typewriterSuffixEnd}
              </span>
            </h1>
          </hgroup>
          <ReserveYourSpotButton />
        </div>
        <div className="bg-gray-900/20 w-full h-full absolute top-0 -z-10" />
        <div className="w-full mt-auto h-28 md:h-26 lg:h-16 absolute bottom-[0px] bg-white">
          <img
            src="/bg_wave.svg"
            className="w-[105%] max-w-[unset] mt-auto absolute left-1/2 top-[1px] transform -translate-y-full -translate-x-1/2"
            alt=""
          />
        </div>
      </section>
      <main className="z-10 relative bg-white">
        <OneOfAKindSection />
        <WeLoveKumbhSection />
        <LocationSection />
        <AccommodationAndAmenitiesSection />
        <ExperienceKumbhSection />
        <JoinUsSection />
      </main>
      <Footer />
    </>
  );
}

const ReserveYourSpotButton: React.FC = () => {
  const getMessage = useMessages();

  const reserveSpotMobile = getMessage('camp67-header.reserve-your-spot.prefix.mobile');
  const reserveSpotDesktop = getMessage('camp67-header.reserve-your-spot.prefix.desktop');
  const secureCheckout = getMessage('camp67-header.reserve-your-spot.secure-checkout');

  const isMobile = useIsMobile();

  const lowestPrice = displayPrice({
    priceInINR: getPrice(getLowestPricedTent()),
    showCurrency: true,
  });

  const onClick = useCallback(() => {
    analyticsState?.amplitude?.track(AmplitudeEvent.BUTTON_CLICK, {
      sectionSource: sections['header'],
      buttonTag: ButtonTag.RESERVE_A_SPOT,
    });
  }, []);

  return (
    <Link
      prefetch="intent"
      to="/packages/reservation"
      className="flex flex-col items-center mt-8 max-w-[600px] m-auto"
    >
      <button onClick={onClick} className="max-w-[85%] relative">
        <div className="w-full px-12 sm:px-14 pt-5 pb-4 bg-blue-100 -skew-x-12 shadow-md-drop shadow-blue-200 rounded-lg lg:px-16 hover:bg-blue-50 hover:shadow-gray-200 transition-all duration-300 ease-in-out">
          <p className="text-sm xl:text-base skew-x-12 uppercase tracking-widest hover:bold flex flex-row gap-0.5">
            {isMobile ? reserveSpotMobile : reserveSpotDesktop} {lowestPrice} →
          </p>
          <div className="-mb-2 w-full pt-2 flex flex-row flex-wrap items-center justify-center">
            <div className="flex flex-row flex-wrap items-center justify-center">
              <Sprite
                name="shield-dark"
                width="14"
                height="14"
                className="skew-x-12 w-4 h-4"
              />
              <p className="skew-x-12 text-xs font-medium ml-1">{secureCheckout}</p>
            </div>
            <div className="flex flex-row items-center">
              <img
                src={'/razorpay.png'}
                alt="Razorpay"
                className="h-3 sm:h-4 ml-2 object-contain skew-x-12"
              />
              <img
                src={'/paypal.png'}
                alt="Paypal"
                className="h-4 sm:h-5 ml-2 object-contain skew-x-12"
              />
            </div>
          </div>
        </div>
      </button>
    </Link>
  );
};
