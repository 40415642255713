import React, { useEffect, useState } from 'react';
import { Section } from '../components/section';
import { sections } from '../hooks/use-section-analytics';
import { Link, useLoaderData } from '@remix-run/react';
import { images } from '../constants/images';
import Sprite from '../sprites/sprite';
import { loader } from '../routes/_index';
import { getDateString } from '@camp67/model/booking/dates';
import { FormattedString, useMessages } from '../i18n/useMessages';
import { MessageKey } from '../locales/en';
import { events } from '../pages/kumbh-2025.content';

interface Quality {
  quality: MessageKey;
  color: 'text-red-200' | 'text-blue-200' | 'text-green-300';
  image: string;
  imageAlt: MessageKey;
  text: React.ReactNode;
  icon: React.ReactNode;
}

const qualities: Quality[] = [
  {
    quality: 'camp67-one-of-a-kind.quality.peaceful.title',
    color: 'text-red-200',
    image: images['peaceful'],
    imageAlt: 'camp67-one-of-a-kind.quality.peaceful.image-alt',
    text: <FormattedString message="camp67-one-of-a-kind.quality.peaceful.text" />,
    icon: (
      <Sprite name="meditate" width="33" height="31" className="min-w-6 md:min-w-8" />
    ),
  },
  {
    quality: 'camp67-one-of-a-kind.quality.safe-and-secure.title',
    color: 'text-blue-200',
    image: images['safe'],
    imageAlt: 'camp67-one-of-a-kind.quality.safe-and-secure.image-alt',
    text: <FormattedString message="camp67-one-of-a-kind.quality.safe-and-secure.text" />,
    icon: <Sprite name="secure" width="27" height="30" className="min-w-6 md:min-w-8" />,
  },
  {
    quality: 'camp67-one-of-a-kind.quality.hygienic.title',
    color: 'text-green-300',
    image: images['hygienic'],
    imageAlt: 'camp67-one-of-a-kind.quality.hygienic.image-alt',
    text: <FormattedString message="camp67-one-of-a-kind.quality.hygienic.text" />,
    icon: (
      <Sprite name="hygienic" width="29" height="29" className="min-w-6 md:min-w-8" />
    ),
  },
];

interface SignificantDate {
  dateKey: MessageKey;
  title: MessageKey;
  description: MessageKey;
  // Raw strings for url params
  date: string;
  endDate: string;
}

const significantDates: SignificantDate[] = events.map(({ name, date, endDate }) => ({
  dateKey: `camp67-one-of-a-kind.calendar.${name}.date`,
  title: `camp67-one-of-a-kind.calendar.${name}.title`,
  description: `camp67-one-of-a-kind.calendar.${name}.description`,
  date,
  endDate,
}));

export const OneOfAKindSection = () => {
  const data = useLoaderData<typeof loader>();

  const [count, setCount] = useState(1000);

  useEffect(() => {
    if (data.success) {
      setCount(data.count);
    }
  }, [data]);

  const getMessage = useMessages();
  const aPremiumDorm = getMessage('camp67-one-of-a-kind.premium.prefix');
  const swtLink = getMessage('camp67-link.sukrit-wellness-tours');
  const fromTeamBehind = getMessage('camp67-one-of-a-kind.team-behind');
  const ksLink = getMessage('camp67-link.kumbh-sukrit-camp');
  const graphic2013 = getMessage('camp67-one-of-a-kind.graphic.2013');
  const graphic2019 = getMessage('camp67-one-of-a-kind.graphic.2019');
  const graphic2025 = getMessage('camp67-one-of-a-kind.graphic.2025.description');
  const graphic2025BookNow = getMessage('camp67-one-of-a-kind.graphic.2025.book-now');

  const calendarBookNow = getMessage('camp67-banner.book-now');

  return (
    <Section className="w-full m-auto" id={sections['oneOfAKind']}>
      <h2 className="px-8 mb-4 sm:mb-0 sm:px-0 text-xl font-serif xl:text-base text-center">
        {aPremiumDorm}{' '}
        <a
          className="text-blue-500 underline"
          href="https://sukritwellness.com"
          target="_blank"
          rel="noreferrer"
        >
          {swtLink}
        </a>
      </h2>
      <p className="uppercase text-center tracking-wide sm:tracking-widest text-gray-500 mt-2 text-xs px-4 sm:px-0">
        {fromTeamBehind}{' '}
        <a
          href="https://kumbhsukrit.com"
          className="underline text-blue-500"
          target="_blank"
          rel="noreferrer"
        >
          {ksLink}
        </a>
      </p>
      <div
        id="past"
        className="mt-6 flex flex-col px-1 sm:px-10 max-w-[720px] w-[95%] m-auto"
      >
        <Graphic text={graphic2013} progress={20} className="bg-[#92E8FF]" year={2013} />
        <Graphic text={graphic2019} progress={75} className="bg-[#92E8FF]" year={2019} />
        <Graphic
          text={
            <>
              {Math.max(20, (count * 100) / 20000).toFixed()}% {graphic2025}
              {' - '}
              <Link
                to="/packages/reservation"
                prefetch="intent"
                className="underline text-blue-500"
              >
                {graphic2025BookNow}
              </Link>
            </>
          }
          progress={20}
          className="bg-[#A1F6A5]"
          year={2025}
        />
      </div>
      <ul className="list-none grid grid-cols-1 xs:grid-cols-3 md:grid-cols-4 w-[95%] max-w-[960px] justify-center m-auto mt-8">
        {significantDates.map(({ date, endDate, dateKey, title, description }, index) => {
          const formattedTitle = getMessage(title);
          const formattedDescription = getMessage(description);
          const formattedFrom = getMessage(dateKey);

          const from = getDateString(date);
          const to = getDateString(endDate);

          return (
            <li
              key={index}
              className="px-5 py-4 rounded-md border border-green-300 shadow-md bg-white flex flex-col gap-1"
            >
              <span className="uppercase text-gray-500 font-serif tracking-widest whitespace-nowrap text-sm">
                {formattedFrom}
              </span>
              <span className="font-serif font-bold text-sm md:text-base">
                {formattedTitle}
              </span>
              <p className="text-sm">{formattedDescription}</p>
              <Link
                to={`/packages/reservation?from=${from}&to=${to}`}
                prefetch="intent"
                className="flex-end justify-self-end"
              >
                <button className="text-sm uppercase tracking-wider text-white rounded-md bg-green-300 px-3 py-2 mt-2">
                  <span>{calendarBookNow}</span>
                </button>
              </Link>
            </li>
          );
        })}
      </ul>
      <div className="mt-8 grid grid-cols-1 md:grid-cols-3 w-full">
        {qualities.map(({ quality, color, image, imageAlt, text, icon }, index) => {
          const formattedImageAlt = getMessage(imageAlt);
          const formattedQuality = getMessage(quality);

          return (
            <article key={index} className="w-full relative h-[320px] md:h-[360px]">
              <img
                src={image}
                alt={formattedImageAlt}
                className="w-full h-full object-cover absolute left-0 top-0 select-none pointer-events-none"
              />
              <div className="w-full h-full absolute left-0 top-0 bg-black/55 sm:bg-transparent sm:bg-gradient-to-b sm:from-black/0 sm:to-black from-35%" />
              <div className="w-[90%] md:w-[unset] absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 sm:translate-x-[unset] sm:translate-y-[unset] sm:top-[unset] sm:left-7 sm:bottom-7">
                <span
                  className={`font-serif font-bold text-3xl xl:text-4xl flex flex-col sm:flex-row items-center justify-center sm:justify-normal gap-0 sm:gap-2 text-center sm:text-left ${color}`}
                >
                  {icon} {formattedQuality}
                </span>
                <p className="mt-3 sm:mt-1 text-white text-sm md:text-base w-[75%] m-auto text-center sm:m-[unset] sm:w-full md:max-w-[320px] sm:text-left">
                  {text}
                </p>
              </div>
            </article>
          );
        })}
      </div>
    </Section>
  );
};

interface GraphicProps {
  year: number;
  text: React.ReactNode;
  progress: number;
  className: string;
}

const Graphic: React.FC<GraphicProps> = ({ year, text, progress, className }) => {
  return (
    <div className="mt-4 sm:mt-3 w-full flex flex-col sm:flex-row items-center gap-1 sm:gap-4">
      <span className="uppercase text-gray-500 font-serif tracking-widest whitespace-nowrap">
        {year} Kumbh
      </span>
      <div className="relative pl-4 py-2.5 bg-gray-100 z-0 w-full rounded-3xl">
        <p>{text}</p>
        <div
          className={`h-full z-[-1] absolute left-0 top-0 rounded-3xl ${className}`}
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
};
