import { amenities, Amenity } from '../components/amenity';
import { tents } from '../constants/tents';
import { Section } from '../components/section';
import { sections } from '../hooks/use-section-analytics';
import Sprite from '../sprites/sprite';
import { GetInTouch } from '../components/get-in-touch/get-in-touch';
import { FAQ, faqs } from '../components/faq/faq';
import { TentExperienceOption } from '../components/booking-form/tent-experience-field';
import { FormattedString, useMessages } from '../i18n/useMessages';

export const AccommodationAndAmenitiesSection = () => {
  const getMessage = useMessages();
  const allAmenities = getMessage(
    'camp67-accommodation-and-amenities.amenities-included',
  );
  const chooseFromPackages = getMessage(
    'camp67-accommodation-and-amenities.choose-from-packages',
  );

  return (
    <Section
      className="w-full m-auto relative z-10"
      id={sections['accommodationAmenities']}
    >
      <Sprite
        name="sun"
        width="210"
        height="175"
        className="w-24 h-24 mb-4 m-auto md:absolute md:left-16 md:w-24 md:h-24 lg:w-52 lg:h-52 lg:left-20"
      />
      <hgroup className="m-auto relative text-center">
        <h2 className="text-sm font-sans uppercase font-bold text-gray-600 tracking-widest xl:text-base text-center">
          {allAmenities}
        </h2>
        <h3 className="font-display text-center text-2xl leading-5 md:text-4xl md:leading-6 xl:text-5xl xl:leading-8 m-auto text-orange-400 mt-9">
          <FormattedString message="camp67-accommodation-and-amenities.come-to-home" />
        </h3>
      </hgroup>
      <p className="mt-6 w-full max-w-120 md:max-w-130 mx-auto md:mx-auto md:w-130 text-center text-lg xl:text-xl xl:mt-6 leading-snug font-sans px-4">
        {chooseFromPackages}
      </p>
      <div className="relative mt-11 w-full">
        <div className="bg-accommodation w-full h-3/4 opacity-10 absolute top-1/2 -translate-y-1/2 -z-10" />
        <ul className="flex flex-row flex-wrap justify-center text-center gap-7 px-6 md:px-10 lg:px-32">
          {Object.values(tents).map((tent) => (
            <div className="w-full lg:w-96" key={tent.tentType}>
              <TentExperienceOption
                type={tent.tentType}
                isDisabled={false}
                isSelected={true}
                showCounts={false}
                onClick={() => {}}
              />
            </div>
          ))}
        </ul>
      </div>
      <div className="m-auto w-full px-6 lg:px-32 mt-12">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 w-full justify-center items-start lg:gap-9">
          {amenities.map((amenity, key) => (
            <Amenity key={key} {...amenity} />
          ))}
        </div>
      </div>
      <h2 className="text-center pl-6 lg:px-32 md:text-left font-serif font-bold mt-12 text-2xl">
        FAQs
      </h2>
      <div className="flex flex-col md:flex-row w-full mt-6 lg:px-32 gap-9">
        <div className="flex flex-col gap-3 w-full px-6 md:pl-6 md:pr-0 lg:px-0">
          {faqs.map((faq, index) => (
            <FAQ {...faq} key={index} />
          ))}
        </div>
        <GetInTouch />
      </div>
    </Section>
  );
};
